import {isObject} from "@/utils/is";
import {isArray} from "lodash";
export function traverse(data,orgId) {
    if (isObject(data)){
        if(data.orgId === orgId){
            return data.orgName
        }
        if (data.subOrgList){
            return traverse(data.subOrgList,orgId)
        }
    }else if(isArray(data)){
        for (let i=0;i < data.length;i++){
            let tempData = data[i]
            if (tempData.orgId === orgId){
                return tempData.orgName
            }
        }
        for (let i=0;i < data.length;i++){
            let name = undefined
            let tempData = data[i]
            if (tempData.subOrgList){
                name = traverse(tempData.subOrgList,orgId)
            }
            if (name === undefined){
                continue
            }else{
                return name
            }
        }
    }
}
