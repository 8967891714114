import request from '@/utils/request'

// 查询项目列表
export function listProject(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/list',
        method: 'get',
        params: query
    })
}

// 查询项目详细
export function getProject(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/' + id,
        method: 'get'
    })
}

// 新增项目
export function addProject(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project',
        method: 'post',
        data: data
    })
}

// 修改项目
export function updateProject(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project',
        method: 'put',
        data: data
    })
}

// 删除项目
export function delProject(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/' + id,
        method: 'delete',
        data: data
    })
}

// 校验项目名称
export function checkName(data){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/check_name',
        method: 'post',
        data: data
    })
}

export function suspend(id, data){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/'+id+'/suspend',
        method: 'put',
        data: data
    })
}

export function terminated(id, data){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/'+id+'/terminated',
        method: 'put',
        data: data
    })
}

export function recover(id, data){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/'+id+'/recover',
        method: 'put',
        data: data
    })
}

export function cancelSuspend(id, data){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/'+id+'/cancel_suspend',
        method: 'put',
        data: data
    })
}

// 项目状态选项
export function getProjectStatusOptions() {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/status/options',
        method: 'get'
    })
}

// 项目状态选项
export function getInstallationPlanProjectOptions(params) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/installation_plan/options',
        method: 'get',
        params
    })
}

// 查询没有安装计划的设备
export function getNoInstallationPlanDevices(id,params){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/'+id+'/no_plan_devices',
        method: 'get',
        params
    })
}

// 查询每个月项目数量
export function getProjectCountEveryMonth(params){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/project_count/statistics',
        method: 'get',
        params
    })
}

// 项目状态统计
export function getProjectStatusStatistics() {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/status/statistics',
        method: 'get'
    })
}

// 查询已签合同的项目选项
export function getSignedProjectOption(){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project/signed/option',
        method: 'get',
    })
}
